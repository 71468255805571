import React from "react";
import Pixel from "../card/ios"

export default () => {
  return(
    <Pixel
      title="Night And Day"
      publishDate="03/12/2021">
      <p>Thank you for downloading this application. Please kindly send your enquiry by using the feedback menu in the app.</p>
    </Pixel>
  )
}
